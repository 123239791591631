import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import Entity from './entities/entity.component';
import { createSurveyDataObject } from '@survey/common/dist/utilities/createSurveyDataObject';
import { getCurrentPageTabs } from '@survey/common/dist/actions/pages.actions';

class PreviewInternalSurveyType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      tab: null,
      data: {}
    };

    ['changePage', 'changeTab'].map(k => (this[k] = this[k].bind(this)));
  }

  changePage(page) {
    // When the page is changed, set the tab to a tab for that page.
    let tabs = getCurrentPageTabs(
      page,
      this.props.allTabs,
      this.props.questions,
      this.props.surveyType.questions,
      this.props.technologyQuestions,
      this.props.surveyType.technologyQuestions,
      this.props.technologies
    );
    let tab = tabs.length ? tabs[0].tabId : 0;
    this.setState({ page, tab });
  }

  changeTab(tab) {
    this.setState({ tab });
  }

  componentDidMount() {
    if (isEmpty(this.state.data) && this.props.surveyType) {
      const data = createSurveyDataObject(this.props.surveyType);
      this.setState({
        data
      });
    }
  }

  render() {
    const { questions, allTabs, pages, answers, surveyType, technologyQuestions, countriesList, productsList, technologiesList, vendorsList, regionsList, regions } = this.props;
    const { data, page } = this.state;

    let pageTabs = getCurrentPageTabs(
      page,
      this.props.allTabs,
      this.props.questions,
      this.props.surveyType.questions,
      this.props.technologyQuestions,
      this.props.surveyType.technologyQuestions,
      this.props.technologies
    );

    return (
      <Entity
        entity={{ entityID: '', prevData: {}, data }}
        surveyType={surveyType}
        isLoading={false}
        allTabs={allTabs}
        pageTabs={pageTabs}
        pages={pages}
        page={page}
        answers={answers}
        questions={questions}
        technologyQuestions={technologyQuestions}
        delegates={{ entities: {} }}
        changeSelection={this.changePage}
        saveSurvey={() => console.log('save survey')}
        countriesList={countriesList}
        regionsList={regionsList}
        productsList={productsList}
        technologiesList={technologiesList}
        vendorsList={vendorsList}
        entityHospitals={[]}
        entityAmbulatories={[]}
        entitySubAcutes={[]}
        entityHomeHealths={[]}
        entityFreeStandingDataCenters={[]}
        entityInHospitalDataCenters={[]}
        entityUntetheredAmbulatories={[]}
        regions={regions}
        survey={{}}
      />
    );
  }
}

export default PreviewInternalSurveyType;
