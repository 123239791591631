import { Button, List, ListItem, Tabs, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import CustomSurveyTab from './CustomSurveyTab';
import SurveyHeader from './surveyHeader';
import SurveyPageCard from './surveyPageCard';
import { getCurrentPageTabs } from '@survey/common/dist/actions/pages.actions';
import CustomForm from '@survey/common/dist/components/form-controls/CustomForm';
import { createSurveyDataObject } from '@survey/common/dist/utilities/createSurveyDataObject';
import { ValidatorForm } from 'react-material-ui-form-validator';
const styles = (theme) => ({
  questionsContainer: {
    backgroundColor: 'white',
    margin: '1rem',
    padding: '1rem',
  },
  tabButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  button: {
    '&:hover': {
      backgroundColor: '#005f8a',
    },
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#005f8a',
  },
  buttonDisabled: {
    backgroundColor: '#E0E0E0',
  },
  activeTabIndicator: {
    backgroundColor: 'white',
  },
});

class PreviewExternalSurveyType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      tab: null,
      data: {},
    };

    ['changePage', 'changeTab', 'backToSurvey', 'switchTab', 'questionsPerPage'].map((k) => (this[k] = this[k].bind(this)));
  }

  changePage(page) {
    // When the page is changed, set the tab to a tab for that page.
    let tabs = getCurrentPageTabs(
      page,
      this.props.allTabs,
      this.props.questions,
      this.props.surveyType.questions,
      this.props.technologyQuestions,
      this.props.surveyType.technologyQuestions,
      this.props.technologies
    );
    let tab = tabs.length ? tabs[0].tabId : 0;
    this.setState({ page, tab });
  }

  changeTab(tab) {
    this.setState({ tab });
  }

  backToSurvey() {
    this.setState({ tab: null, page: null });
  }

  switchTab(direction = 'next') {
    let tabs = getCurrentPageTabs(
      this.state.page,
      this.props.allTabs,
      this.props.questions,
      this.props.surveyType.questions,
      this.props.technologyQuestions,
      this.props.surveyType.technologyQuestions,
      this.props.technologies
    );
    const idx = tabs.findIndex((t) => t.tabId === this.state.tab);
    if (idx > -1) {
      let newIdx = idx + 1;
      if (direction === 'prev') newIdx = idx - 1;
      this.setState({ tab: tabs[newIdx].tabId });
    } else {
      console.log('ERROR SWITCHING TABS');
    }
  }

  questionsPerPage(page) {
    /* Determine the maximum number of branching questions for a page and use that number. */
    let count = 0;

    let tabs = getCurrentPageTabs(
      page,
      this.props.allTabs,
      this.props.questions,
      this.props.surveyType.questions,
      this.props.technologyQuestions,
      this.props.surveyType.technologyQuestions,
      this.props.technologies
    );
    tabs.forEach((tab) => {
      let questions = [];
      if (typeof page === 'string' && page.startsWith('tq_')) {
        questions = this.props.technologyQuestions.filter((q) => {
          return q.technologies.length === 0 || q.technologies.includes(tab.tabId);
        });
      } else {
        questions = this.props.questions.filter((q) => {
          return tab.tabId === q.tabID;
        });
      }
      count += questions.length;
      //count += getAllBranchingQuestions(questions);
    });
    return count;
  }

  componentDidMount() {
    if (isEmpty(this.state.data) && this.props.surveyType) {
      const data = createSurveyDataObject(this.props.surveyType);
      this.setState({
        data,
      });
    }
  }

  render() {
    const {
      answers,
      classes,
      surveyType,
      questions,
      technologyQuestions,
      pages,
      allTabs,
      technologies,
      backToSurvey,
      countriesList,
      regionsList,
      vendorsList,
      technologiesList,
      productsList,
      regions,
    } = this.props;
    const { data, page, tab } = this.state;

    let pageTabs = getCurrentPageTabs(
      page,
      this.props.allTabs,
      this.props.questions,
      this.props.surveyType.questions,
      this.props.technologyQuestions,
      this.props.surveyType.technologyQuestions,
      this.props.technologies
    );
    let techQuestionsForm = typeof page === 'string' && page.startsWith('tq_');

    let currentPage = pages.find((p) => p.pageId === page);
    let pageName = currentPage ? currentPage.pageName : '';

    const isLastTab = () => {
      if (!pageTabs) return false;
      if (pageTabs[pageTabs.length - 1].tabId === tab) return true;
      return false;
    };
    const buttonStyle = { root: classes.button, disabled: classes.buttonDisabled };
    if (surveyType.surveyTemplate === 'One Page') {
      return (
        <ValidatorForm onSubmit={() => {}} onError={() => {}} className={this.props.classes.form}>
          <div style={{ backgroundColor: '#cbdae0', padding: '1rem' }}>
            <Typography variant="h5" style={{ marginLeft: '1.5rem', color: '#78777a', fontSize: '30px' }}>
              {surveyType.name}
            </Typography>
            <div className={classes.questionsContainer}>
              {pages.map((pg) => {
                const currTabs = getCurrentPageTabs(pg.pageId, allTabs, questions, surveyType.questions, technologyQuestions, surveyType.technologyQuestions, technologies);

                return currTabs.map((tab) => {
                  return (
                    <CustomForm
                      key={tab.tabId}
                      tabId={tab.tabId}
                      techQuestionsForm={techQuestionsForm}
                      entity={{ prevData: {}, data }}
                      questions={questions}
                      technologyQuestions={technologyQuestions}
                      answers={answers}
                      onSubmit={() => console.log('save survey')}
                      onError={(errors) => console.log(errors)}
                      countriesList={countriesList}
                      regionsList={regionsList}
                      productsList={productsList}
                      technologiesList={technologiesList}
                      vendorsList={vendorsList}
                      regions={regions}
                      allQuestions={techQuestionsForm ? this.props.technologyQuestions : this.props.surveyType.questions}
                    />
                  );
                });
              })}
            </div>
            <Button
              variant="contained"
              classes={buttonStyle}
              onClick={() => backToSurvey()}
              style={{
                marginLeft: '1.5rem',
              }}
            >
              Save
            </Button>
          </div>
        </ValidatorForm>
      );
    } else {
      return (
        <ValidatorForm onSubmit={() => {}} onError={() => {}} className={this.props.classes.form}>
          <div style={{ backgroundColor: '#cbdae0', padding: '1rem' }}>
            <SurveyHeader pageName={pageName} goBack={this.backToSurvey} />
            {page !== null && (
              <div className={classes.questionsContainer}>
                <Tabs classes={{ indicator: classes.activeTabIndicator }} value={tab} onChange={(e, value) => this.changeTab(value)}>
                  {pageTabs.map((item, index) => {
                    return <CustomSurveyTab key={item.tabId} index={index + 1} value={item.tabId} label={item.tabName} />;
                  })}
                </Tabs>
                <CustomForm
                  key={tab}
                  tabId={tab}
                  techQuestionsForm={techQuestionsForm}
                  entity={{ prevData: {}, data }}
                  questions={techQuestionsForm ? technologyQuestions : questions}
                  answers={answers}
                  onSubmit={() => console.log('save survey')}
                  onError={(errors) => console.log(errors)}
                  countriesList={countriesList}
                  regionsList={regionsList}
                  productsList={productsList}
                  technologiesList={technologiesList}
                  vendorsList={vendorsList}
                  regions={regions}
                  allQuestions={techQuestionsForm ? this.props.technologyQuestions : this.props.surveyType.questions}
                />
                <div className={classes.tabButtons}>
                  <Button key="back-button" disabled={pageTabs && pageTabs[0].tabId === tab ? true : false} variant="contained" classes={buttonStyle} onClick={() => this.switchTab('prev')}>
                    Back
                  </Button>
                  <div>
                    <Button
                      variant="contained"
                      classes={buttonStyle}
                      onClick={() => this.backToSurvey()}
                      style={{
                        display: isLastTab() ? 'inline-block' : 'none',
                        marginRight: '0.5rem',
                      }}
                    >
                      Save
                    </Button>
                    <Button variant="contained" classes={buttonStyle} onClick={() => this.switchTab('next')} disabled={isLastTab()}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {page === null && (
              <List>
                {pages.map((item) => {
                  return (
                    <ListItem key={item.pageId}>
                      <SurveyPageCard key={item.pageId} numberOfQuestions={this.questionsPerPage(item.pageId)} item={item} onClick={() => this.changePage(item.pageId)} />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
        </ValidatorForm>
      );
    }
  }
}

export default withStyles(styles)(PreviewExternalSurveyType);
