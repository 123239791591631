import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Toolbar, Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { cloneDeep, find, findIndex, isEmpty, isEqual } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { handleToastMessage } from '../../layout/layout.actions';
import { getSurveyTypes } from '../../surveyTypes/surveyTypes.actions';
import Entity from './entity.component';

import { createSurveyState, createSurveyStateForEntity } from '@survey/common/dist/utilities/createSurveyState';
import { InternalProgressBar } from '@survey/common/dist/components/tables/internalProgressBar';
import { calculatePageStatus } from '@survey/common/dist/utilities/surveyUtils';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { saveSurvey, saveSurveyChanges, getSurvey, setSurvey, getSurveyScore, submitSurvey } from '@survey/common/dist/actions/surveys.actions';
import { getCurrentPageTabs, getPages } from '@survey/common/dist/actions/pages.actions';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getTechnologies, getTechnologiesList } from '@survey/common/dist/actions/technologies.actions';
import { getTechnologyQuestions } from '@survey/common/dist/actions/technologyQuestions.actions';
import { getVendorsList } from '@survey/common/dist/actions/vendors.actions';
import { getProductsList } from '@survey/common/dist/actions/products.actions';
import { getRegionsList, getCountriesList, getRegions } from '@survey/common/dist/actions/countries.actions';
//import {
//  getEntityAmbulatories,
//  getEntityFreeStandingDataCenters,
//  getEntityHomeHealths,
//  getEntityHospitals,
//  getEntityInHospitalDataCenters,
//  getEntitySubAcutes,
//  getEntityUntetheredAmbulatories,
//} from '@survey/common/dist/actions/entities.actions';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  survey: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    width: 240,
    backgroundColor: theme.palette.background.default,
  },
  button: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
    justifyContent: 'left',
    textTransform: 'none',
  },
  submitButton: {
    float: 'right',
  },
  content: {
    width: '100%',
    padding: theme.spacing(1),
    height: '100%',
  },
  card: {
    margin: theme.spacing(1),
  },
});

class EntityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: {},
      questions: [],
      surveyType: {},
      technologyQuestions: [],
      pages: [],
      tabs: [],
      entity: {},
      score: {},
      showSubmitDialog: false,
    };
    ['changeSelection', 'updateSurvey', 'updateSurveyChanges', 'clickSaveSurvey', 'completePage', 'submitSurvey'].forEach((k) => {
      this[k] = this[k].bind(this);
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { survey, pages, tabs, technologies } = props;
    if (survey && props.surveyTypes.length && pages.length && tabs.length && technologies.length) {
      let entity = find(survey.entities, { entityID: Number(props.match.params.entityId) });
      if (!isEqual(entity, state.entity)) {
        const surveyType = survey.surveyType ? survey.surveyType : find(props.surveyTypes, { surveyTypeID: survey.surveyTypeID });
        if (surveyType && entity) {
          const newState = createSurveyStateForEntity(entity, survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies);
          let pageQuestionCount = [];

          const entityPages = cloneDeep(newState.pages);
          const pagesWithProgress = entityPages.map((p) => {
            if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
              p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
            }

            return p;
          });

          pagesWithProgress.forEach((p) => {
            const { surveyType, tabs } = newState;
            const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);
            pageQuestionCount.push({ pageId: p.pageId, totalQuestions: pageStatus.totalPageQuestions, tabQuestionCount: pageStatus.tabQuestionCount });
          });

          state = { ...state, ...newState, entity, pageQuestionCount };
        }
      }
    }
    return state;
  }

  componentDidMount() {
    const {
      survey,
      pages,
      tabs,
      questions,
      technologyQuestions,
      surveyTypes,
      technologies,
      vendorsList,
      productsList,
      technologiesList,
      countriesList,
      regionsList,
      regions,
    } = this.props;

    (isEmpty(survey) || survey.surveyID !== Number(this.props.match.params.surveyId)) && this.props.getSurvey(this.props.match.params.surveyId);
    isEmpty(pages) && this.props.getPages();
    isEmpty(tabs) && this.props.getTabs();
    isEmpty(questions) && this.props.getQuestions();
    isEmpty(technologyQuestions) && this.props.getTechnologyQuestions();
    isEmpty(surveyTypes) && this.props.getSurveyTypes();
    isEmpty(technologies) && this.props.getTechnologies();
    isEmpty(vendorsList) && this.props.getVendorsList();

    isEmpty(productsList) && this.props.getProductsList();
    isEmpty(technologiesList) && this.props.getTechnologiesList();
    isEmpty(regionsList) && this.props.getRegionsList();
    isEmpty(regions) && this.props.getRegions();
    isEmpty(countriesList) && this.props.getCountriesList();

    console.log('PROPS', this.props);

    this.getCurrentStageAndAddToState(this.props.match.params.surveyId, this.props.match.params.entityId);
  }

  changeSelection(selection) {
    const { surveyId, entityId } = this.props.match.params;
    this.props.history.push(`/surveys/${surveyId}/entities/${entityId}/${selection}`);
  }
  async updateSurveyChanges(surveyID, entityID, changes) {
    const response = await this.props.saveSurveyChanges(surveyID, entityID, changes);
  }
  updateSurvey(surveyID, entityID, data) {
    let entities = [...this.props.survey.entities];
    const idx = findIndex(this.props.survey.entities, { entityID });
    // Exit if we can't find the entity to update.
    if (idx === -1) return;

    entities[idx] = { ...entities[idx], data };
    let survey = cloneDeep(this.props.survey);
    /* Set the status to In Progress if it hasn't been already */
    if (survey.status !== 'In Progress') {
      survey.status = 'In Progress';
    }
    survey.entities = entities;

    delete survey.bsonId;
    // TODO:  We should just remove the questions and technologyQuestions entirely but right now that will delete them from the survey
    survey.questions.forEach((q) => {
      delete q.bsonId;
    });
    survey.technologyQuestions.forEach((q) => {
      delete q.bsonId;
    });

    this.props.saveSurvey(surveyID, survey);
  }

  async clickSaveSurvey() {
    const { survey } = this.state;
    if (survey.surveyID) {
      delete survey.bsonId;
      // TODO:  We should just remove the questions and technologyQuestions entirely but right now that will delete them from the survey
      survey.questions &&
        survey.questions.forEach((q) => {
          delete q.bsonId;
        });
      survey.technologyQuestions &&
        survey.technologyQuestions.forEach((q) => {
          delete q.bsonId;
        });

      const response = await this.props.saveSurvey(survey.surveyID, survey);

      if (response.type === 'SAVE_SURVEY_SUCCESS') {
        //no need to set survey state since saveSurvey already updates it
        //this.props.setSurvey(survey);

        this.props.handleToastMessage('Survey responses saved successfully.', false);
      } else {
        this.props.handleToastMessage('Survey responses failed to save!', true);
      }
    }
  }

  async completePage(page, changes) {
    const { entity } = this.state;
    //await this.saveSurvey(survey);

    const survey = cloneDeep(this.props.survey);

    survey.pageProgress[`${entity.entityID}-${page.pageId}`] = {
      percentComplete: 100,
      completed: true,
    };

    changes.pageProgress = { ...survey.pageProgress };
    const response = await this.props.saveSurveyChanges(survey.surveyGuid, entity.entityID, changes);

    if (response.type === 'SAVE_SURVEY_CHANGES_SUCCESS') {
      this.props.handleToastMessage('Survey responses saved successfully.', false);
    } else {
      this.props.handleToastMessage('Survey responses failed to save!', true);
    }
  }

  addEntityProgress(entity) {
    const { survey, pages, tabs, technologies } = this.props;
    const { surveyType } = this.state;
    let totalQuestionsAnswered = 0;
    let totalQuestions = 0;

    if (!isEmpty(survey) && !isEmpty(surveyType) && !isEmpty(pages)) {
      const surveyState = createSurveyState(survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies);

      const pagesWithProgress = surveyState.pages.map((p) => {
        if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
          p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
        }

        return p;
      });

      pagesWithProgress.forEach((p) => {
        const { surveyType, tabs } = surveyState;
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        totalQuestions += pageStatus.totalQuestions;

        if (p.pageProgress && p.pageProgress.completed === true) {
          totalQuestionsAnswered += pageStatus.totalQuestions;
        } else {
          totalQuestionsAnswered += pageStatus.totalQuestionsAnswered;
        }
      });
    }

    const progress = totalQuestions > 0 ? Math.floor((totalQuestionsAnswered / totalQuestions) * 100) / 100 : 0;
    return { ...entity, progress };
  }

  async getCurrentStageAndAddToState(surveyID, entityID) {
    let res = await this.props.getSurveyScore(surveyID);

    let currentEntityScore = find(res.response, function (entity) {
      return entity.haEntityID == entityID;
    });

    if (currentEntityScore) {
      this.setState({ stage: currentEntityScore.stage });
    } else {
      this.setState({ stage: 'N/A' });
    }
  }

  async submitSurvey() {
    /* Hide the dialog */
    this.setState({ showSubmitDialog: false });

    const survey = { ...this.props.survey, status: 'Submitted for QA Review' };

    /* Remove keys that will cause the submission to fail. */
    delete survey.bsonId;
    survey.questions.forEach((q) => {
      delete q.bsonId;
    });
    survey.technologyQuestions.forEach((q) => {
      delete q.bsonId;
    });

    /* Save the survey */
    let allowSubmit = ['Not Started', 'In Progress'];
    if(!allowSubmit.includes(survey.status)){
      this.props.handleToastMessage('Failed to submit survey!', true);
    }else{
      const response = await this.props.submitSurvey(this.props.survey.surveyID);
      if (response.type === 'SUBMIT_SURVEY_SUCCESS') {
        this.props.handleToastMessage('Survey successfully submitted.', false);
        this.props.history.push('/');
      } else {
        this.props.handleToastMessage('Failed to submit survey!', true);
      }
    }
  }

  render() {
    const {
      classes,
      countriesList,
      isLoading,
      isSaving,
      match,
      productsList,
      regionsList,
      survey,
      technologies,
      technologiesList,
      vendorsList,
      regions,
    } = this.props;
    const { pages, surveyType, questions, tabs, technologyQuestions, score, pageQuestionCount } = this.state;
    let entity = this.state.entity;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    let sortedPages = cloneDeep(pages);

    //if the survey is AMAM we need to sort sections by pageId ASC
    if (surveyType.name === 'AMAM') {
      sortedPages = sortedPages.sort((a, b) => (a.pageId > b.pageId ? 1 : -1));
    }

    const pagesWithProgress = sortedPages.map((p) => {
      if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
        p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
      } else {
        p.pageProgress = {
          completed: false,
          progress: 0,
        };
      }

      return p;
    });

    entity = this.addEntityProgress(entity);

    const curPageIndex = match.params.pageId && findIndex(pagesWithProgress, { pageId: match.params.pageId.startsWith('tq') ? match.params.pageId : Number(match.params.pageId) });
    const currentPage = curPageIndex !== -1 && pagesWithProgress[curPageIndex];
    const currentTabs = currentPage ? getCurrentPageTabs(String(currentPage.pageId), tabs, questions, surveyType.questions, technologyQuestions, surveyType.technologyQuestions, technologies) : [];

    //see if this is single survey
    let entitiesLength = 0;
    if (survey.entities) {
      entitiesLength = survey.entities.length;
    }

    function determineColor(value) {
      if (value > 5) {
        return <b style={{ color: 'red' }}>{value}</b>;
      }

      return <span>{value}</span>;
    }

    const stageText = surveyType.scoringType === 'DHI' || surveyType.scoringType === 'DHIShort' ? 'DHI Score: ' : 'Current Stage: ';

    return (
      <Fragment>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.survey}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="hospital" />
              </Avatar>
              <div className={classes.heading}>
                <Typography variant="h6" color="inherit" onClick={() => this.props.history.push(`/surveys/${survey.surveyID}`)}>
                  {survey.surveyName}
                </Typography>
                <Typography style={{ display: 'flex' }} variant="subtitle1" color="inherit" onClick={() => this.props.history.push(`/surveys/${survey.surveyID}/entities/${entity.entityID}`)}>
                  {entity.entityName} : {entity.entityDescription}
                  <div style={{ width: 300, marginLeft: '1rem' }}>
                    <InternalProgressBar value={entity.progress} />
                  </div>
                  <div style={{ width: 300, marginLeft: '1rem' }}>
                    {stageText} {determineColor(this.state.stage)}
                  </div>
                </Typography>
                <Confirm
                  title="Submit Survey?"
                  onClose={() => this.setState({ showSubmitDialog: false })}
                  onConfirm={() => this.submitSurvey()}
                  contentText={`Please confirm you are ready to submit this survey.`}
                  open={this.state.showSubmitDialog}
                />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Entity
          allTabs={tabs}
          answers={survey.answers}
          changeSelection={this.changeSelection}
          completePage={this.completePage}
          countriesList={countriesList}
          entity={entity}
          //entityAmbulatories={entityAmbulatories}
          //entityFreeStandingDataCenters={entityFreeStandingDataCenters}
          //entityHomeHealths={entityHomeHealths}
          //entityHospitals={entityHospitals}
          //entityInHospitalDataCenters={entityInHospitalDataCenters}
          //entitySubAcutes={entitySubAcutes}
          //entityUntetheredAmbulatories={entityUntetheredAmbulatories}
          isLoading={isLoading}
          isSaving={isSaving}
          page={currentPage}
          pageTabs={currentTabs}
          pages={pagesWithProgress}
          productsList={productsList}
          questions={questions}
          regionsList={regionsList}
          saveSurvey={this.updateSurvey}
          saveSurveyChanges={this.updateSurveyChanges}
          clickSaveSurvey={this.clickSaveSurvey}
          surveyType={surveyType}
          technologies={technologies}
          technologiesList={technologiesList}
          technologyQuestions={technologyQuestions}
          vendorsList={vendorsList}
          regions={regions}
          survey={survey}
          pageQuestionCount={pageQuestionCount}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    countriesList: state.countries.get('countriesList'),
    //entityAmbulatories: state.entities.get('entityAmbulatories'),
    //entityFreeStandingDataCenters: state.entities.get('entityFreeStandingDataCenters'),
    //entityHomeHealths: state.entities.get('entityHomeHealths'),
    //entityHospitals: state.entities.get('entityHospitals'),
    //entityInHospitalDataCenters: state.entities.get('entityInHospitalDataCenters'),
    //entitySubAcutes: state.entities.get('entitySubAcutes'),
    //entityUntetheredAmbulatories: state.entities.get('entityUntetheredAmbulatories'),
    pages: state.pages.get('pages'),
    productsList: state.products.get('productsList'),
    questions: state.questions.get('questions'),
    regionsList: state.countries.get('regionsList'),
    regions: state.countries.get('regions'),
    survey: state.surveys.get('survey'),
    surveyTypes: state.surveyTypes.get('surveyTypes'),
    tabs: state.tabs.get('tabs'),
    technologies: state.technologies.get('technologies'),
    technologiesList: state.technologies.get('technologiesList'),
    technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
    vendorsList: state.vendors.get('vendorsList'),
    isLoading:
      state.surveys.get('isLoading') ||
      state.surveyTypes.get('isLoading') ||
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.questions.get('isLoading') ||
      state.technologies.get('isLoading') ||
      state.vendors.get('isLoading') ||
      state.products.get('isLoading') ||
      state.countries.get('isLoading') ||
      state.entities.get('isLoading'),
    isSaving: state.surveys.get('isSaving'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCountriesList,
    //getEntityAmbulatories,
    //getEntityFreeStandingDataCenters,
    //getEntityHomeHealths,
    //getEntityHospitals,
    //getEntityInHospitalDataCenters,
    //getEntitySubAcutes,
    //getEntityUntetheredAmbulatories,
    getPages,
    getProductsList,
    getQuestions,
    getRegionsList,
    getRegions,
    getSurvey,
    getSurveyTypes,
    getSurveyScore,
    getTabs,
    getTechnologies,
    getTechnologiesList,
    getTechnologyQuestions,
    getVendorsList,
    handleToastMessage,
    saveSurvey,
    saveSurveyChanges,
    setSurvey,
    submitSurvey,
  })(EntityContainer)
);
